import React from 'react';
import { useNavigate } from 'react-router-dom';
import './IntroPage.css'; // Optional: create a CSS file for styling
import { Button } from '@mui/material';

function IntroPage() {
  const navigate = useNavigate();

  const startSurvey = () => {
    navigate('/waterstone/spiritual-gifts/start');
  };

  return (
    <div className="intro-page">
            <h2>WaterStone Church</h2>
            <h5>Spiritual Gifts Assessment</h5>
      <p>At WaterStone Church we try to place our volunteers in areas of service that 
        relate to their God given spiritual gifts. A Spiritual Gift is a special ability 
        that has been given to you by God for Christian service, as mentioned in Scripture.</p>

  <p>The assessment targets 12 spiritual gifts:<strong> Administration, Discernment, Encouragement, 
    Faith, Giving, Helps, Knowledge, Leadership, Mercy, 
    Prophecy, Teaching, and Wisdom.</strong></p>
    <p>You may have received more than one gift, and we 
    will review with you your top three after this assessment. This test will take approximately 5-10 minutes to complete.
 </p>
      <Button variant="contained" onClick={startSurvey}>Start Assessment</Button>
      < br />
      <p><small>The following gifts are debatably ceased: Healing, Interpretation, Languages, Miracles. Moreover, validation of the gifts would require physical confirmation not made
      available in an online test.
      </small></p>
    </div>
  );
}

export default IntroPage;
